
$(document).ready(function(){

  $('.lang-item').on('click',function(){
    $('.lang-sub').slideToggle();
  });

  $('.speakers-tabs__item').on('click',function(){
    $('.speakers-tabs__item').removeClass('active');
    $(this).addClass('active');

    var dataTab = $(this).data('tab');
    
    $('.speakers-content').fadeOut();
    $('.speakers-content[data-tab='+ dataTab +']').fadeIn();
  });


  $('.topic-tabs__item').on('click',function(){
    $('.topic-tabs__item').removeClass('active');
    $(this).addClass('active');

    var dataTab = $(this).data('tab');
    
    $('.topic-content').fadeOut();
    $('.topic-content[data-tab='+ dataTab +']').fadeIn();
  });


  $('.topic-trigger').on('click',function(){
    $(this).toggleClass('active');
    $(this).closest('.topic-item').find('.topic-item__info').slideToggle();
  });

  $('.calendar-tab').on('click',function(){
    $('.calendar-tab').removeClass('active');
    $(this).addClass('active');

    var dataTab = $(this).data('tab');
    
    $('.calendar-content').fadeOut();
    $('.calendar-content[data-tab='+ dataTab +']').fadeIn();
  });

  $('.calendar-lobby__item').on('click',function(){
    $('.calendar-lobby__item').removeClass('active');
    $(this).addClass('active');

    var dataLobby = $(this).data('lobby');
    
    $('.calendar-info').fadeOut();
    $('.calendar-info[data-lobby='+ dataLobby +']').fadeIn();
  });

  $('.close-modal').on('click',function(){
    $('.modal,.overlay').fadeOut();
  });

  $('.auth').on('click',function(e){
    e.preventDefault();
    $('.recall-modal,.overlay').fadeIn();
  });
  
  $('.registration').on('click',function(e){
    e.preventDefault();
    $('.registration-modal,.overlay').fadeIn();
  });

  $('.modal-next1').on('click',function(){
    $('.modal-step1').hide();
    $('.modal-step2').show();
    $(this).closest('.modal').find('.modal-top').addClass('active1');
  });

  $('.modal-next2').on('click',function(){
    $('.modal-step2').hide();
    $('.modal-step3').show();
    $(this).closest('.modal').find('.modal-top').removeClass('active1');
    $(this).closest('.modal').find('.modal-top').addClass('active2');
  });

  var swiper = new Swiper('.swiper-container1', {
    slidesPerView: 2,
    spaceBetween: 30,
    loop: true,
    navigation: {
      nextEl: '.photo-next',
      prevEl: '.photo-prev',
    },
  });

  
});